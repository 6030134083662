import { Container, Stack } from "@mui/material";
import AssignmentCard from "./AssignmentCard";
import HubMainTabs from "../navigation/HubMainTabs";
import "./Assignments.scss";

const Assignments = () => {
  return (
    <Container className="assignment-container" data-testid="assignments">
      <HubMainTabs />
      <Stack>
        <AssignmentCard />
        <AssignmentCard />
      </Stack>
    </Container>
  );
};

export default Assignments;
