import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoadScreen from "../LoadScreen";
import Assignments from "../../features/assignments/Assignments";
import MainLayout from "../../framework/layouts/MainLayout";
import Mission from "../../features/mission/Mission";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    mode: "light",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Assignments />,
        },
        {
          path: ":mission_id",
          element: <Mission />,
        },
      ],
    },
    {
      path: "*",
      element: <div>404</div>,
    },
  ],
  {
    basename: "/hub-management",
  }
);

const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <>
      {authStatus === "authenticated" ? (
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </QueryClientProvider>
      ) : (
        <LoadScreen fullscreen={true} />
      )}
    </>
  );
};

export default () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);
