const Mission = () => {
  return (
    <>
      <h1>Mision</h1>
      <div data-testid="test-container" className="card">
        <p className="read-the-docs">Mission Details Placeholder</p>
      </div>
    </>
  );
};

export default Mission;
