import {
  Box,
  Typography,
  Divider,
  Chip,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Task from "../tasks/Task";
import { useState } from "react";

const AssignmentCard = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <Accordion
      expanded={expanded}
      className="assignment"
      onChange={handleToggle}
    >
      <AccordionSummary>
        <Box className="assignment-card" data-testid="assignment-card">
          <Box className="assignment-card__title">
            <Typography className="assignment-card__title__mission_id">
              VEH-XXX
            </Typography>
            <div className="assignment-card__title__vehicle">
              <Typography>TORC-C10</Typography>
              <Divider orientation="vertical" />
              <Typography>TRL-0005</Typography>
            </div>
          </Box>
          <Box className="assignment-card__info">
            <Chip label="POST-TRIP" />
            <Button data-testid="open-assignments" onClick={() => handleToggle}>
              <AssignmentIcon className="clipboard" />
              <Typography>2 assigned to me</Typography>
              {expanded ? (
                <ExpandLessIcon className="expand" />
              ) : (
                <ExpandMoreIcon className="expand" />
              )}
            </Button>
          </Box>
          <Box className="assignment-card__date">
            <Typography>departs at 12:10 CT</Typography>
          </Box>
          <Box className="assignment-card__status">
            <Chip label="TRACKING ON TIME" />
          </Box>
          <Box className="assignment-card__more">
            <ChevronRightIcon />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack className="assignment-tasks">
          {/* placeholder data */}
          <Task assignee="David J." />
          <Task />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssignmentCard;
