import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Button,
} from "@mui/material";
import "./assets/HubMainTabs.scss";
import { useState } from "react";

const HubMainTabs = () => {
  const [active, setActive] = useState("mine");

  const handleActive = (e) => {
    setActive(e.currentTarget.value);
  };
  return (
    <Box className="hub-nav">
      <Box className="hub-nav__selection">
        <Typography>My Hub: </Typography>
        <FormControl variant="standard">
          <Select
            labelId="hub-select"
            id="hub-select"
            value={"Dallas, TX"}
            label="Hub"
          >
            <MenuItem value={"Dallas, TX"}>Dallas, TX</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider orientation="vertical" className="hub-nav__divider" />
      <Box className="hub-nav__tabs">
        <Button
          data-testid="my_assignments"
          value="mine"
          className={active == "mine" ? "active" : ""}
          onClick={(e) => handleActive(e)}
        >
          My Assignments
        </Button>
        <Button
          data-testid="all_assignments"
          value="all"
          className={active == "all" ? "active" : ""}
          onClick={(e) => handleActive(e)}
        >
          All Assignments
        </Button>
      </Box>
    </Box>
  );
};

export default HubMainTabs;
