import { Box, Typography, Chip, Icon, Button } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserIcon from "./assets/user_icon.svg";
import "./Task.scss";

const Task = ({ assignee }) => {
  return (
    <Box className="task" data-testid="task">
      <Box className="task__type">
        <AssignmentIcon />
        <Typography>Post Trip Inspection</Typography>
      </Box>
      <Box className="task__assignee">
        {assignee ? (
          // placeholder data
          <Box className="task__assignee__details">
            <Icon>
              <img alt={assignee} src={UserIcon} />
            </Icon>
            <Typography>{assignee}</Typography>
            <Button className="task__assignee__details__actions">
              <MoreVertIcon />
            </Button>
          </Box>
        ) : (
          <Chip label="assign to me" />
        )}
      </Box>
      <Box className="task__status">
        <Typography>not started</Typography>
      </Box>
    </Box>
  );
};

export default Task;
