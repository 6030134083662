import React from "react";
import { Amplify } from "aws-amplify";
import { UserProvider } from "@torc-robotics/mcli-mfui-auth";
import awsExports from "./aws-config";
import App from "./components/App/App";
import { DatadogContextProvider } from "./framework/contexts/Datadog";

Amplify.configure(awsExports);
const Root = ({ datadogRum, datadogLogs }) => {
  return (
    <UserProvider>
      <DatadogContextProvider
        datadogRumClient={datadogRum}
        datadogLogsClient={datadogLogs}
      >
        <App />
      </DatadogContextProvider>
    </UserProvider>
  );
};
export default Root;
